<template>
  <div class="page">
    <div class="formcontainer">
      <div class="formbox">
        <h2>Hizmet Sağlayıcılar için Başvuru Formu</h2>
        <tabs-new-basvuru current="2" hide="6"></tabs-new-basvuru>
        <tabs-edevlet-basvuru-sub
          v-if="loggedIn"
          current="3"
          hide="6"
        ></tabs-edevlet-basvuru-sub>
        <tabs-eimza-basvuru-sub
          v-else
          current="3"
          hide="6"
        ></tabs-eimza-basvuru-sub>

        <!-- <div class="formhead"> -->
        <!-- <div>
            <div>
              Bu sayfada ilgili belgelerin elektronik imzanız ile imzalanarak ayrılan alanlara yüklenmesi işlemleri
              yapılmaktadır.
              <br />
              Yüklediğiniz belgeler hesap yöneticilerimiz tarafından kontrol edilerek
              onaylanacaktır. Onaylama sonrası tarafınıza e-posta ve mesaj ile bildirim yapılacaktır.
            </div>
        </div>-->
        <!-- </div> -->

        <div class="formhead" v-if="appType === 'EDEVLET'">
          <p>
            Başvurunuza devam edebilmek için lütfen aşağıdaki Kullanım
            Taahhütnamesi'ni okuyup onaylayınız.
          </p>
        </div>

        <h3 v-if="appType === 'EIMZA'" class="mtmb">
          Elektronik İmzalı Kullanım Taahhütnamesi
        </h3>
        <!-- <h3 v-if="appType === 'EDEVLET'" class="mt">Kullanım Taahhütnamesi</h3> -->

        <div v-if="appType === 'EIMZA'" class="formpart">
          <div class="formright formcard">
            <div class="formline">
              <h4>Belge İndirme</h4>
              <!--
              <a
                download
                href="/pdf/iys_kullanim_taahhutnamesi.pdf"
                class="downloadform"
                target="_blank"
                ><img src="@/assets/img/ico_downloadform.svg" alt />Kullanım
                Taahhütnamesi'ni İndir</a
              >-->

              <a
                @click="TRACK('Belge_Indir')"
                download
                :href="`${BFF}/hspreapp/file/download/hizmet-sozlesmesi/${application.appNo}`"
                class="downloadform"
                target="_blank"
              >
                <img src="@/assets/img/ico_download.svg" alt />Kullanım
                Taahhütnamesi'ni İndir
              </a>

              <!-- <p>Elektronik olarak imzalayacağınız belgeyi indirin.</p> -->
            </div>

            <div class="formline caution">
              <h4>Dikkat Edilmesi Gereken Noktalar</h4>

              <ul>
                <li>
                  <p>
                    Öncelikli olarak,
                    <b>İYS Temel Hizmetler Kullanım Taahhütnamesi</b>
                    'ni elektronik imzanızla imzalamanız gerektiği için lütfen
                    bağlantıyı kullanarak bu belgeyi indiriniz.
                    <!-- <a v-else @click="downloadHS" class="download cursor"
                        >Kullanım Taahhütnamesi'ni İndir</a
                    >-->
                  </p>
                </li>
                <!-- <li>
                  <p>
                    Müştereken temsile yetki durumunda en az bir yetkilinin daha
                    ilk yetkili tarafından imzalanmış dokümanı imzalaması
                    gerekmektedir.
                  </p>
                </li>-->

                <li>
                  <p>
                    Yüklediğiniz belge hesap yöneticilerimiz tarafından kontrol
                    edilerek onaylanacaktır. Kontrol sonrası tarafınıza e-posta
                    ve mesajla bildirim yapılacaktır.
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="formleft">
            <div class="formline">
              <h4>Belge Yükleme</h4>

              <form
                action="#"
                onsubmit="return false;"
                class
                v-if="belge_adet < 1"
              >
                <div v-if="!hzmt_kapat">
                  <!-- <div class="withbtn"></div> -->
                  <input
                    type="file"
                    id="hzmt"
                    ref="my_hzmt"
                    name="#"
                    value
                    class="inputfile"
                    @click="CLICK_EKLE($event)"
                    @change="FILE_EKLE_CHANGED($event, 'HIZMET_SOZLESMESI')"
                    accept=".pdf"
                  />
                  <label for="hzmt" class="cursor">
                    <img src="@/assets/img/ico_upload.svg" alt />Kullanım
                    Taahhütnamesi Yükle
                  </label>
                </div>
              </form>

              <div class="formleft formcard" v-if="hzmt_kapat">
                <div class="files">
                  <div class="fileline" v-for="file in fileSet" :key="file.id">
                    <img
                      :src="
                        require(`@/assets/img/${file_type_map['application/pdf']}`)
                      "
                    />

                    <div class="filetext">
                      <div class="filetype">
                        {{ belge_turu_map[file.docType].str }}
                      </div>
                      <div class="filename">
                        {{ file.fileName | toFileName }}
                      </div>
                    </div>

                    <div class="okim">
                      <img src="@/assets/img/ico_tick_green.svg" alt />
                    </div>
                    <div
                      class="delete"
                      @click="DELETE_FILE_CLICK(file.docType)"
                    >
                      <img src="@/assets/img/ico_remove.svg" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="formline" v-if="!next">
              <div class="file">
                <h4>Elektronik İmza Yardım</h4>
                <div class="video full" @click="SET_MODAL('ModalVideo')">
                  <img
                    class="art"
                    src="@/assets/img/hero/video_elektronik_imza.png"
                    alt="PDF doküman elektronik imzayla nasıl imzalanır."
                  />
                  <img
                    class="playbtn"
                    src="@/assets/img/hero/play.svg"
                    alt="Play"
                  />
                </div>
                <div class="desc mt">
                  <a
                    target="_blank"
                    href="https://get.adobe.com/tr/reader/"
                    class="blue"
                    >Adobe Acrobat</a
                  >
                  yazılımının güncel versiyonunun bilgisayarınızda kurulu
                  olduğundan emin olunuz.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="appType === 'EDEVLET'" class>
          <div class="formcard">
            <kullanim-taahhutnamesi-kabul
              v-model="hizmet_sozlesmesini_okudum"
            ></kullanim-taahhutnamesi-kabul>
          </div>
        </div>

        <sonraki-bar
          :dsbl="!next"
          @next="NEXT"
          :no-app-no="true"
          :friction="500"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import SonrakiBar from "./genel/SonrakiBar";
import download from "../../../misc/download";

export default {
  name: "BelgeYuklemeOnBasvuru",
  components: {
    SonrakiBar,
  },

  data() {
    return {
      appType: "EDEVLET",
      hizmet_sozlesmesini_okudum: false,
      BFF: process.env.VUE_APP_BFF_API,
      str_plus_1: "+1 Ekle",
      BFF: process.env.VUE_APP_BFF_API,
      belge_turu_map: require("../../../misc/enums").ENUM_BELGE_TURU,
      file_type_map: require("../../../misc/enums").ENUM_BELGE_UZANTISI_SVG,
      problem: {
        file: "",
      },
    };
  },

  computed: {
    ...mapState("applicant", ["application"]),

    fileSet() {
      return this.application.fileSet.filter(
        (file) => file.docType == "HIZMET_SOZLESMESI"
      );
    },

    belge_adet() {
      return this.fileSet.length;
    },

    hzmt_kapat() {
      return this.belge_adet > 0;
    },

    next() {
      return this.appType === "EIMZA"
        ? this.hzmt_kapat
        : this.hizmet_sozlesmesini_okudum;
    },
  },

  created() {
    this.appType = this.loggedIn ? "EDEVLET" : "EIMZA";
  },

  async mounted() {
    await this.BELGE_LISTESI_UPDATE();
  },

  methods: {
    ...mapActions("applicant", [
      "belge_yukle",
      "belge_sil_by_type",
      "belge_listesi_cek",
    ]),
    ...mapMutations("app", ["SET_MODAL"]),

    ...mapMutations("applicant", ["SET_BELGE_LISTESI"]),
    ...mapMutations("app", ["SET_MODAL"]),

    TRACK(name) {
      // _paq.push(["trackEvent", "Basvuru", name, "Success", 1]);
    },

    // downloadHS() {
    //   download(
    //     `${this.BFF}hspreapp/file/download/hizmet-sozlesmesi/${this.application.appNo}`
    //   );
    // },

    CLICK_EKLE(e) {
      e.srcElement.value = "";
    },

    BELGE_LISTESI_UPDATE() {
      this.belge_listesi_cek().then((res) => {
        this.SET_BELGE_LISTESI(res.payload.fileSet);
      });
    },

    async FILE_EKLE_CHANGED(e, belge_turu) {
      if (e.srcElement.value === "") return;

      let target = e.target;

      if (target.files[0] === undefined) {
        await this.Alert("Dosya seçimi başarısız oldu.");
        this.$env.CONSOLE.warn("FILE UNDEFINED");
        return;
      }

      try {
        const fileSize = (target.files[0].size / 1024 / 1024).toFixed(4);

        if (fileSize > 12) {
          await this.Alert("Dosya boyutu 12 MB'dan küçük olmalıdır.");
          return;
        }

        if (fileSize === 0) {
          await this.Alert("Dosya boyutu 0 MB olamaz.");
          return;
        }
      } catch (error) {}

      this.belge_yukle({
        path: this.belge_turu_map[belge_turu].api_path,
        file: target.files[0],
        brandName: "",
      })
        .then((res) => {
          // _paq.push(["trackEvent", "Basvuru", "Belge_Yukle", "Success"]);
          this.BELGE_LISTESI_UPDATE();
        })
        .catch((cerror) => {
          // _paq.push(["trackEvent", "Basvuru", "Belge_Yukle", "Error"]);
          this.HandleValidationError(cerror);
        });
    },

    DELETE_FILE_CLICK(docType) {
      this.belge_sil_by_type(docType).then((res) => {
        this.BELGE_LISTESI_UPDATE();
      });
    },

    NEXT() {
      if (!this.next && !this.loggedIn) {
        this.Alert("Lütfen Kullanım Taahhütnamesi'ni yükleyiniz.");
        return;
      }

      if (!this.hizmet_sozlesmesini_okudum && this.loggedIn) {
        this.Alert(
          "Lütfen 'Temel Hizmetler Kullanım Taahhütnamesi'ni okudum ve onaylıyorum' kutucuğunu işaretleyiniz."
        );
        return;
      }

      this.$router.push("/hizmet-saglayici/basvuru/marka-tanimlama-on-basvuru");
    },
  },
};
</script>

<style scoped>
.lds-dual-ring {
  background: #e8e8e8;
  margin-left: -2px;
}

.link a {
  color: #4da0df;
}
</style>
