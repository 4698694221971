<template>
  <div class="formfooter">
    <div class="basvuruno">
      <div
        v-if="plabel"
        class="btn back"
        :class="{ dsbl: pdsbl }"
        @click="back"
      >
        <a>{{ plabel }}</a>
      </div>

      <basvuru-no-box v-if="!noAppNo">{{ application.appNo }}</basvuru-no-box>

      <div v-if="label" class="btn next" :class="{ dsbl }" @click="next">
        <a>{{ label }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SonrakiBar",

  props: {
    //p: geri butonu
    noAppNo: {
      type: Boolean,
      required: false
    },
    plabel: {
      type: String,
      default: "Önceki Adım"
    },
    label: {
      type: String,
      default: "Sonraki Adım"
    },
    pdsbl: {
      type: Boolean,
      default: false
    },
    dsbl: {
      type: Boolean,
      default: false
    },
    friction: {
      type: Number,
      required: false,
      default: 0
    },
    backto: {
      type: String,
      default: ""
    }
  },

  computed: {
    ...mapState("applicant", ["application"])
  },

  methods: {
    back() {
      if (this.friction) {
        let loader = this.$loading.show();
        setTimeout(() => {
          loader.hide();
          if (!this.backto) {
            this.$router.go(-1);
          } else {
            this.$router.push(this.backto);
          }
        }, this.friction);
      } else {
        if (!this.backto) {
          this.$router.go(-1);
        } else {
          this.$router.push(this.backto);
        }
      }
    },
    next() {
      if (this.friction) {
        let loader = this.$loading.show();
        setTimeout(() => {
          loader.hide();
          this.$emit("next");
        }, this.friction);
      } else {
        this.$emit("next");
      }
    }
  }
};
</script>
